.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.image-backgraound {
  margin-left: auto;
  width: 20%;
}
