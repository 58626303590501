* {
  padding: 0%;
  margin: 0%;
}

body {
  width: 100vw;
  height: 100vh;
}

.fondo {
  background-color: #ffffff !important;
  background-image: url("https://backend-smarteco.vertebra-tech.com/downloads/avatars/fondo.png") !important;
}

.container {
  display: -webkit-flex !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
}

.login-item {
  width: 100%;
  height: 100%;
  min-width: 850px;
  min-height: fit-content;
  flex-direction: column;

  padding: 1rem;
  margin: 2px;
  border-radius: 0.5rem;

  background: rgb(222, 249, 233);
  background: linear-gradient(
    318deg,
    rgba(222, 249, 233, 1) 0%,
    rgba(255, 255, 255, 1) 80%
  );
  color: #000;
  transition: all 0.3s ease;

  border: solid 0.1rem #e3fbe1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.login-item-form {
  width: 100%;
  height: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;
  max-width: 360px;
}

.login-item .login-item__content {
  display: flex;
}

.form-login {
  width: 40%;
}

.colapsable-login {
  width: 60%;
}

.footer {
  display: block;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
  border-top: 1px;
}

.text-login {
  color: grey;
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
  word-spacing: pre-wrap;
}

.link-login {
  color: #009432;
  text-decoration: underline;
  cursor: pointer;
  word-spacing: pre-wrap;
  font-size: 1rem;
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
  .form-login {
    width: 100%;
  }

  .colapsable-login {
    width: 0%;
    display: none;
  }
  .login-item {
    width: 100%;
    min-height: fit-content;
    min-width: 100%;
    max-width: 350px;
    max-height: 600px;
    padding: 0.7rem;
  }
  .footer {
    font-size: 0.8rem;
  }
  .text-login {
    font-size: 0.8rem;
  }
  .link-login {
    font-size: 0.8rem;
  }
}

.logo-login {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 150px;
  object-fit: cover;
  border-radius: 0.5rem;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-item:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border: solid transparent;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerCredentials {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.card-bacground {
  background: linear-gradient(45deg, #fff, #28d872);
}

.card-pass {
  content: "";
  position: absolute;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 100% 100%;
  background: linear-gradient(45deg, #fff, #28d872);
  right: 0%;
  opacity: 0.2;
}

.forms-containers {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.forms-containers-credentials {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.crendentials {
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 30%);
}

.title-form {
  font-size: 1.5rem;
  color: #444;
}

.singIn {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  display: grid;
  grid-template-columns: 1rf;
}

form {
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.singIn {
  z-index: 2;
}

.rounder {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.gradient {
  background: linear-gradient(160deg, #fff, #9aceac);
}
