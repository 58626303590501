.ecoEficiencia-scroll-bar-col {
  overflow: auto !important;
  flex: 1;
  position: sticky;
  top: 0;
  max-height: 88vh;
  overflow-y: auto;
}
.ecoEficiencia-scroll-bar-col::-webkit-scrollbar {
  width: 0; /* Oculta el ancho de la scrollbar en navegadores WebKit */
  height: 0;
}
.text-water{
  color: #1e96fc;
}
.text-energy{
  color: #009432;
}
/* .ant-input-search button {
  background-color:#1e96fc;
  border: #1e96fc;
}
.ant-input-search button:hover {
  background-color:#3a9ff8;
  border: #1e96fc;
} */