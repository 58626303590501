.pdf-control-panel {
  font-size: 16px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  background-color: #219432;
}

.img-control-panel {
  font-size: 16px;
  color: #ffffff;
  justify-content: center;
  display: flex;
  margin-left: -2px;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: fit-content;
  background-color: #219432;
}

.clickable {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: not-allowed;
  color: lightgray;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.view-pdf-button {
  font-size: 0.8rem;
  color: #ffffff;
}

.view-pdf-button :focus {
  color: #ffffff;
}

.view-pdf-button:hover {
  color: #fff000;
}

.view-pdf-button:active {
  color: #fff000;
}
