.pdf {
  width: 100%;
  height: 100%;
}

.imag {
  width: 100%;
  height: 100%;
}

.pdf-container {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.image-container {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  border-radius: 1px solid green;
}

::-webkit-scrollbar-thumb {
  background: green;
  border-radius: 6px;
}
