.back-left {
  background: #009432 !important;
  position: absolute;
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.radius-flex {
  background: #009432 !important;
  border-radius: 50%;
  position: absolute;
  display: flex;
  height: 130vh;
  margin-top: -15%;
  width: 25vw;
  right: 85%;
  z-index: 0;
}

.formulario {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.footer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  z-index: 2;
  bottom: 15px;
  width: 100%;
  z-index: 0;
}
