span .ant-input-group-addon{
    background: linear-gradient(100deg, #009432, #0f9b0f)
}

div .ant-modal-header{
    background: #009432;
}

div .ant-drawer-header{
    background: #009432;
}