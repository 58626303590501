.table-border-today {
  border-left: 6px solid #54b435;
  padding-left: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 100px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.table-border-yesterday {
  border-left: 6px solid #fd841f;
  padding-left: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 100px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.table-border-day-after-yesterday {
  border-left: 6px solid #ea5455;
  padding-left: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 100px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.table-border-default {
  border-left: 6px solid #ea5455;
  padding-left: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 100px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
}
