body {
  min-height: 100%;
  min-width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

body>iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}