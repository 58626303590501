.text-overflow {
  overflow: hidden;
  display: inline-block;
  width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-overflow-transaction {
  overflow: hidden;
  display: inline-block;
  width: 150;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-overflow-bills {
  overflow: hidden;
  display: inline-block;
  width: 80%;
  bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-overflow-manualAddConciliatios {
  overflow: hidden;
  display: inline-block;
  font-size: 0.8rem;
  width: 35%;
  bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 20%;
  }
}

.image {
  width: 100%;
  height: 100%;
}

.image-pay-modal {
  min-width: 600px;
  min-height: 400px;
  width: 100%;
  height: auto;
  margin: 10px;
  object-fit: fill;
}

.title-project {
  font-size: 18px;
  margin-left: 38%;
}

.text-overflow-accounts {
  font-size: 12px;
  overflow: hidden;
  display: inline-block;
  width: 100px;
  bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-button {
  font-size: 1.2rem;
  white-space: pre-wrap;
  color: #ff5c58;
  cursor: pointer;
  opacity: 0.9;
}

.delete-button:hover {
  font-size: 1.2rem;
  white-space: pre-wrap;
  color: #ff5c58;
  cursor: pointer;
}

.add-button {
  font-size: 1.2rem;
  white-space: pre-wrap;
  color: #6ecb63;
  cursor: pointer;
  opacity: 0.9;
}

.add-button:active {
  font-size: 1.2rem;
  white-space: pre-wrap;
  color: #6ecb63;
  cursor: pointer;
}

.width-tooltip {
  width: 500px;
}
