.scroll-div {
  width: 100%;
  height: fit-content;
  max-height: 40vh;
  overflow: hidden;
  overflow-y: scroll;
}

.scroll-comment {
  width: 100%;
  height: 160px;
  overflow: hidden;
  overflow-y: scroll;
}

.scroll-comment-bills-layout {
  width: 100%;
  height: 40vh;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: scroll;
}
.scroll-comment-bills-layout::-webkit-scrollbar {
  width: 9px;
}

.scroll-digitation {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.scroll-dash-div {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
}
.scroll-div-domais {
  width: 100%;
  height: 20vh;
  min-height: auto;
  overflow: hidden;
  overflow-y: scroll;
}

.scroll-reports {
  width: 100%;
  height: 23vh;
  overflow: hidden;
  overflow-y: scroll;
}

.drop-button {
  width: 100%;
}

.scroll-div {
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  overflow-y: scroll;
}

.scroll-div-interactions {
  width: 100%;
  min-width: 200px;
  height: fit-content;
  max-height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
  min-height: 300px;
  padding: 5px;
  margin: 5px;
}

.scroll-div-users {
  width: 100%;
  min-width: 200px;
  height: fit-content;
  max-height: 40vh;
  overflow: hidden;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  border-radius: 1px solid green;
}

::-webkit-scrollbar-thumb {
  background: green;
  border-radius: 6px;
}

.border-card {
  border: 1px solid #1aa142;
}

.scroll-div-exts-clients {
  width: 100%;
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}
.div-tooltip {
  width: 100%;
  height: fit-content;
  max-width: 100px;
  overflow: hidden;
  overflow-y: scroll;
}

.comment-div-tooltip {
  width: 98%;
  height: fit-content;
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
}

.comment-list-item-scroll {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  max-height: 155px;
  padding: 5px;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.concepts-list-item-scroll {
  width: 100%;
  height: fit-content;
  max-height: 150px;
  overflow: hidden;
  overflow-y: scroll;
}

.top-div-tooltip {
  width: 100%;
  height: fit-content;
  max-height: 180px;
  overflow: hidden;
  overflow-y: scroll;
}

.comment-div-user-bill-download {
  width: 100%;
  height: fit-content;
  max-height: 50px;
  overflow: hidden;
  overflow-y: scroll;
}

.paybills-div-content {
  width: 100%;
  height: fit-content;
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  border-radius: 1px solid green;
}

::-webkit-scrollbar-thumb {
  background: green;
  border-radius: 6px;
}

.border-card {
  padding: -20px;
}

.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}
