.dashboard {
  display: flex;
  width: 10;
}

.ant-table-cell {
  padding: 0%;
}

.table-danger {
  background-color: #f7edee;
}

.table-danger:hover {
  background-color: #f7edee;
}

.table-danger:focus {
  background-color: #f7edee;
}

.table-danger:active {
  background-color: #f7edee;
}
