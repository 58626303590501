.table-row-light {
  background-color: #f6ffed;
}
.table-row-dark {
  background-color: #fef1ef;
}

.tooltip {
  background-color: #87d068;
  width: 100%;
}

.table-pointer:hover {
  cursor: pointer;
}
